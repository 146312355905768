var Backbone = require('backbone');
var mediator = require('../mediator');
var Handlebars = require('handlebars');
var Moment = require('moment');

module.exports = Backbone.Model.extend({

  attachEvents: function () {

    var self = this;
    this.listenTo(mediator, 'i18n:ready', function () {

      mediator.trigger('template:helpers', Handlebars);

      // Grab handlers
      var i18nStatus = {};
      mediator.trigger('i18n:get', i18nStatus);

      mediator.trigger('template:ready');
    });

    this.listenTo(mediator, 'template:helpers', function (Handlebars) {
      self.registerHelpers(Handlebars);
    });
  },

  compile: function (text) {
    return Handlebars.compile(text);
  },

  /**
   * Allow attaching of functions to the mediator to make them 'global'
   * @param mediator
   */
  mediatorAlter: function (mediator) {
    if (!mediator.template) {
      mediator.template = {};
    }

    mediator.template.file = this.templateFile;
    mediator.template.text = this.templateText;
    mediator.template.compile = this.compile;
  },

  /**
   * Create new 'helpers' Handlebars can use in its templating.
   *
   * @param Handlebars
   */
  registerHelpers: function (Handlebars) {
    Handlebars.registerHelper('barGraph', function (question, response, options) {
      var ranges = 0;

      if (!question || !response || !options || !response.response) {
        return '';
      }

      if (!options.hash.ticks) {
        options.hash.ticks = 6;
      }

      var participant = mediator.alter('participant');

      var width;
      var sections = [];
      var min = null;
      var max = null;

      for (var i in question.range) {

        // Check condition on this range before adding it
        if (question.range[i].conditions) {
          var result = mediator.alter('condition', {
            conditions: question.range[i].conditions
          });

          if (result.success === false) {
            continue;
          }
        }

        for (var j in question.range[i].ranges) {

          ranges = ranges + question.range[i].ranges.length;

          sections.push(question.range[i].ranges[j]);

          if (question.range[i].ranges[j].min ||
              question.range[i].ranges[j].min === 0) {
            if (min === null ||
                question.range[i].ranges[j].min < min) {
              min = question.range[i].ranges[j].min;
            }
          }

          if (question.range[i].ranges[j].max ||
              question.range[i].ranges[j].max === 0) {
            if (max === null ||
                question.range[i].ranges[j].max > max) {
              max = question.range[i].ranges[j].max;
            }
          }
        }
      }

      if (ranges === 1) {
        return '';
      }

      width = max - min;

      // Calculate left and width for sections
      for (i in sections) {
        sections[i].top = (parseInt(i) + 1) * -30;
        sections[i].left = ((sections[i].min - min) / width) * 100;

        if (sections[i].left < 0) {
          sections[i].left = 0;
        }

        sections[i].width = ((sections[i].max - sections[i].min) / width) * 100;

        if (question.step) {
          sections[i].width += (question.step / width) * 100;
          // OXYGEN-374 - remove step offset in graph to properly align YOU marker
          //  sections[i].left -= (question.step / width) * 100;
        }

        if (sections[i].left < 0) {
          sections[i].left = 0;
        }

        if (sections[i].left + sections[i].width > 100) {
          sections[i].width = 100 - sections[i].left;
        }

        sections[i].z = parseInt(i) + 100;
      }

      var you = 0;
      var hidden = '';

      // If NA, hide marker entirely
      // If OOR, throw you marker way out of bounds, it will be corrected below
      if (response.response === "NA") {
        hidden = 'visibility: hidden; ';
      } else if (response.response === "OOR Too Low") {
        you = -1000;
      } else if (response.response === "OOR Too High") {
        you = 1000;
      } else {
        you = ((response.response - min) / width * 100);
      }

      if (you < 0) {
        you = 0;
      }

      if (you > 100) {
        you = 100;
      }

      var list = [];
      list.push('<div class="barGraph">');
      list.push('  <div class="row">');
      list.push('    <div class="small-12 medium-12 large-12 columns">');
      list.push('      <div class="bubble" style="' + hidden + 'left: ' + you + '%">YOU</div>');
      list.push('      <div class="bg" style="height: 30px; width: 100%">');

      for (var i in sections) {
        list.push('        <div style="text-align: center; padding-top: 7px; font-size: .7rem; top:' + sections[i].top + 'px;left:' + sections[i].left + '%;width:' +
            sections[i].width + '%;z-index:' + sections[i].z +
            '" max="' + max + '" min="' + min + '" class="section" data-status="' + sections[i].status + '" title="">' +
            '</div>');
      }

      list.push('     </div>');

      var markers = [];

      // Generate ticks on each section boundary.

      for (var i = 0; i < sections.length; i++) {
        var tick = {};

        // Check if this section bar is not the same color as the previous, if it is then skip the marker
        if (i === 0 || sections[i].status !== sections[i - 1].status) {

          tick.value = Math.round(sections[i].min * 100) / 100; // Round to the nearest 2 digits, drop the decimal if zero remainder
          tick.top = (-30 * (markers.length + 1));
          tick.left = sections[i].left;
          tick.align = 'left';

          markers.push(tick);
        }

        // If this is the last range, add a final tick to the end of the graph
        if (i === sections.length - 1) {
          var end_tick = {};
          end_tick.value = Math.round(sections[i].max); // nice round number for the end
          end_tick.top = (-30 * (markers.length + 1));
          end_tick.left = 0;
          end_tick.align = 'right';

          markers.push(end_tick);
        }
      }

      list.push('      <div class="bg" style="height: 30px; width: 100%">');

      for (var i = 0; i < markers.length; i++) {
        list.push('    <div style="text-align:' + markers[i].align + ';top:' + markers[i].top + 'px; left:' + markers[i].left + '%" class="section">');
        list.push('      ' + markers[i].value);
        list.push('    </div>');
      }

      list.push('      </div>');
      list.push('    </div>');
      list.push('  </div>');
      list.push('</div>');

      return list.join('\n');
    });

    // TODO: Move these to another module?
    Handlebars.registerHelper('date', function (date, options) {
      if (!date) {
        date = new Date();
      }

      if (!options || !options.hash || !options.hash.format) {
        format = 'MM/DD/YYYY';
      } else {
        format = options.hash.format;
      }

      if (!date) {

        return '';
      }

      return new Handlebars.SafeString(Moment(date)
          .utcOffset(0)
          .format(format));
    });

    Handlebars.registerHelper('datetime', function (date, options) {

      if (!options || !options.hash || !options.hash.format) {
        format = 'MM/DD/YYYY h:mm:ss a';
      } else {
        format = options.hash.format;
      }

      if (!date) {

        return '';
      }

      return new Handlebars.SafeString(Moment(date)
          .utcOffset(0)
          .format(format));
    });

    Handlebars.registerHelper('email', function (email) {
      return '<a href="mailto:' + email + '">' + email + '</a>';
    });

    Handlebars.registerHelper('firstInitial', function (text) {
      if (text) {

        return text.substring(0, 1);
      } else {

        return '';
      }
    });

    Handlebars.registerHelper('gender', function (gender) {
      if (gender === 'm') {

        return 'Male';
      } else {

        return 'Female';
      }
    });

    Handlebars.registerHelper('height', function (heightInInches) {
      heightInInches = parseInt(heightInInches);
      var feet = Math.floor(heightInInches / 12);
      var inches = heightInInches - (feet * 12);

      return feet + ' feet ' + inches + ' inches';
    });

    Handlebars.registerHelper('list', function (question, options) {
      if (!options.hash.response) {
        return '';
      }

      var responses = options.hash.response.split(';');
      var list = [];
      var output = '<ul>';

      for (var i in responses) {

        // Search question
        for (var j in question.options) {
          if (question.options[j].value == responses[i]) {
            output += '<li>' + question.options[j].text + '</li>';
            list.push(question.options[j].text);
          }
        }
      }

      output += '</ul>';

      return new Handlebars.SafeString(output);
    });

    Handlebars.registerHelper('phone', function (phone) {
      return phone;
    });

    Handlebars.registerHelper('responseText', function (value, options) {
      if (!options) {
        options = {};
      }

      if (!options.hash) {
        options.hash = {};
      }

      var response = options.hash.response;

      if (value && value.options) {
        for (var i in value.options) {
          if (value.options[i].value === response) {
            return value.options[i].text;
          }
        }
      }

      return 'N/A';
    });

    Handlebars.registerHelper('responsePrint', function (response, options) {
      if (!options) {
        options = [];
      }

      // Return existing response value by default
      var responseText = response;

      // If this is matching a question option (e.g. OOR condition), use the text provided instead of the response value
      for (var i in options) {

        if (response === options[i].value) {
          responseText = options[i].text;
          break;
        }
      }

      return responseText;
    });

    Handlebars.registerHelper('round', function (value, options) {
      if (!options) {
        options = {};
      }

      if (!options.hash) {
        options.hash = {};
      }

      if (!options.hash.decimals) {
        options.hash.decimals = 2;
      }

      var returnString = new Handlebars.SafeString(
          parseFloat(value)
              .toFixed(options.hash.decimals)
      );

      if (returnString.string === 'NaN') {
        returnString.string = 'N/A';
      }

      return returnString;
    });

    Handlebars.registerHelper('status', function (value) {
      if (value === 'normal') {
        return '<span title="Normal" data-status="normal" class="fa fa-fw fa-check-circle fa-2x"></span>';
      }

      if (value === 'warning') {
        return '<span title="Warning" data-status="warning" class="fa fa-fw fa-exclamation-circle fa-2x"></span>';
      }

      if (value === 'critical') {
        return '<span title="Critical" data-status="critical" class="fa fa-fw fa-flash fa-2x"></span>';
      }

      if (value === 'invalid') {
        return '<span title="Invalid" data-status="invalid" class="fa fa-fw fa-warning fa-2x"></span>';
      }

      return '';
    });

    Handlebars.registerHelper('uniqueID', function (uniqueID) {
      uniqueID = mediator.alter('uniqueID', {
        value: uniqueID
      });

      return uniqueID.value;
    });
  },

  templateFile: function (text, context, callback) {
    callback(text);
  },

  templateText: function (text, context) {
    var compiled = Handlebars.compile(text);

    return compiled(context);
  }
});
