var BaseView = require('./base');
var mediator = require('../mediator');
var InputView = require('./input');

module.exports = BaseView.extend({
  beforeRender: function() {

  },

  events: {
    "click button": function(e) {
      this.submitForm();
    },
    "keyup input": function (e) {
      if(e.keyCode == 13){
        this.submitForm();
      }
    }
  },

  submitForm: function() {
    var current_password = this.$el.find('[name=current_password]').val();
    var new_password = this.$el.find('[name=new_password]').val();
    var confirm_password = this.$el.find('[name=confirm_password]').val();
    mediator.trigger('account:passwd', {
      username: this.account.username,
      password: current_password,
      new_password: new_password,
      confirm_password: confirm_password
    }, function(err, returnData) {
      // TODO: Shake with error

    });
  },

  initialize: function() {
    var self = this;
    this.account = mediator.alter('account');
  },

  suspend: function() {
    clearTimeout(this.timeout);
  },

  template: 'accountPage.mustache',

  views: {
    ".current_password": new InputView({
      autocorrect: "off",
      default: "",
      faIcon: "",
      label: '',
      name: "current_password",
      placeholder: "Current Password",
      required: true,
      type: "password",
      value: ""
    }),
    ".new_password": new InputView({
      autocorrect: "off",
      default: "",
      faIcon: "",
      label: '',
      name: "new_password",
      placeholder: "New Password",
      required: true,
      type: "password",
      value: ""
    }),
    ".confirm_password": new InputView({
      autocorrect: "off",
      default: "",
      faIcon: "",
      label: '',
      name: "confirm_password",
      placeholder: "Confirm Password",
      required: true,
      type: "password",
      value: ""
    })
  }

});