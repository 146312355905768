var BaseView = require('./base');
var mediator = require('../mediator');
var DashboardAccountItemView = require('./dashboardAccountItem');

module.exports = BaseView.extend({

  afterRender: function () {

    var self = this;

    // Make API call to grab screening list
    this.items = [];
    mediator.api("account/list", {}, function (err, returnData) {

      if (err) {

        return;
      }

      var view;
      self.$el.find('.items').empty();

      for (var i in returnData) {
        view = new DashboardAccountItemView(returnData[i]);
        self.items.push(view);
        self.$el.find('.items').append(view.$el);
        view.render();
      }
    });
  },

  beforeRender: function () {
    //this.options.screening = mediator.alter('screening');
  },

  events: {
    "click button.add": function () {
      window.location.hash = '#dashboard/accountEdit';
    }
  },

  template: 'dashboardAccounts.mustache'
});