var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({

  alter: {
    account: function(data) {
      for (var i in this.attributes) {
        data[i] = this.attributes[i];
      }
    },

    // Default pwgen function.  May be over-ridden in custom config module 'alter'
    pwgen: function(data) {
      var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890"; // abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890
      var length = 8;

      var pass = "";
      for (var x = 0; x < length; x++) {
          var i = Math.floor(Math.random() * chars.length);
          pass += chars.charAt(i);
      }

      data.password = pass;
    },

    controllers: function(controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {
      data.accounts = {
        fa: 'fa-user',
        page: 'dashboard/accounts',
        text: 'Manage Accounts',
        weight: 400
      };
    },

    routes: function(routes) {
      routes.login = {
        controller: 'login',
        data: {},
        name: 'login'
      };

      routes['account'] = {
        controller: 'account',
        data: {
          title: "Account Management"
        },
        name: "accounts"
      };

      routes['dashboard/accounts'] = {
        controller: 'dashboardAccounts',
        data: {
          title: "Account Management"
        },
        name: "dashboard/accounts"
      };

      routes['dashboard/accountEdit'] = {
        controller: 'dashboardAccountEdit',
        data: {
          title: "Add Account"
        },
        name: "dashboard/accountEdit"
      };

      routes['dashboard/accountEdit/:accountID'] = {
        controller: 'dashboardAccountEdit',
        data: {
          title: "Edit Account"
        },
        name: "dashboard/accountEdit"
      };

      routes['dashboard/accountReset/:accountID'] = {
        controller: 'dashboardAccountReset',
        data: {
          title: "Reset Account Password"
        },
        name: "dashboard/accountReset"
      };
    }
  },

  attachEvents: function() {

    var self = this;
    this.listenTo(mediator, 'account:login', function(data, callback) {

      self.login(data.username, data.password, function(err, returnData) {
        callback(err, returnData);
      });
    });

    this.listenTo(mediator, 'account:passwd', function(data, callback) {

      self.passwd(data.username, data.password, data.new_password, data.confirm_password, function(err, returnData) {
        callback(err, returnData);
      });
    });

    this.listenTo(mediator, 'icon:show', function(icon, callback) {
      jQuery("#icons ." + icon)
          .show();
    });

    this.listenTo(mediator, 'icon:hide', function(icon, callback) {
      jQuery("#icons ." + icon)
          .hide();
    });

    this.listenTo(mediator, 'restart', function(data, callback) {
      clearInterval(mediator.eventCheckInterval);
      setTimeout(function() {
        window.location.reload();
      }, 5000);
    });
  },

  controllers: {
    login: function($el, data) {
      var PageView = require('../views/loginPage');
      var view = new PageView();

      return view;
    },

    account: function($el, data) {
      var PageView = require('../views/accountPage');
      var view = new PageView();

      return view;
    },

    dashboardAccounts: function ($el, data) {
      var PageView = require('../views/dashboardAccounts');
      var view = new PageView(data);

      return view;
    },

    dashboardAccountEdit: function ($el, data) {
      var PageView = require('../views/dashboardAccountEdit');
      var view = new PageView(data);

      return view;
    },

    dashboardAccountReset: function ($el, data) {
      var PageView = require('../views/dashboardAccountReset');
      var view = new PageView(data);

      return view;
    }

  },

  hasPermission: function(permission) {
    if (this.has('permissions') === false) {
      return false;
    }

    var permissions = this.get('permissions');

    if (!permissions || permissions.indexOf(permission) == -1) {
      return false;
    }

    return true;
  },

  requiresPasswordChange: function() {
    return (this.get('forcePasswordChange') === true);
  },

  login: function(username, password, callback) {
    mediator.log('Logging in...');
    var self = this;

    mediator.api('account/login', {
      username: username,
      password: password
    }, function(err, returnData) {

      if (err) {
        mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

        return;
      }

      self.set(returnData);

      if (self.requiresPasswordChange() === true) {
        mediator.trigger("toast:success", 'Login Success, but you must change your password');
        mediator.trigger("page:show", "account");
      }
      else {
        mediator.trigger("toast:success", '{{t "account.loginSuccess" }}');
        mediator.trigger("page:home");
        jQuery('#mainMenu')
            .show();
        jQuery('footer')
            .show();
      }

      callback(err, returnData);
    });
  },

  passwd: function(username, password, new_password, confirm_password, callback) {
    mediator.log('Changing password...');
    var self = this;

    // validate new password match
    if (new_password !== confirm_password) {
      mediator.trigger("toast:warning", '{{ t "account.passwordsDoNotMatch" }}');
      callback('New password does not match');

      return;
    }

    mediator.api('account/passwd', {
      username: username,
      password: password,
      new_password: new_password
    }, function(err, returnData) {

      if (err) {
        mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

        return;
      }

      mediator.trigger("toast:success", '{{t "account.passwordChangeSuccess" }}');
      mediator.trigger("page:home");
      jQuery('#mainMenu')
          .show();
      jQuery('footer')
          .show();

      callback(err, returnData);
    });
  }

});