var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  events: {
    "click [data-close]": function() {
      mediator.trigger("modal:close");
    }
  },

  template: 'dashboardAccountConfirmation.mustache'
});