var BaseView = require('./base');
var Backbone = require('backbone');
var mediator = require('../mediator');
var ConfirmationView = require('../views/dashboardAccountConfirmation');

module.exports = BaseView.extend({

  afterRender: function () {

    var self = this;

    if (this.options.args &&
        this.options.args[0] &&
        this.model.has('username') === false) {
      mediator.api("account/get", {
        username: this.options.args[0]
      }, function (err, data) {
        self.model.set(data);
      });
    }

  },

  events: {
    'click [data-cancel]': function () {
      window.history.back();
    },

    'click [data-save]': function () {
      this.save();
    },

    'click [data-pwgen]': function () {
      // Generate a temporary password
      var password = mediator.alter('pwgen').password;
      this.$el.find('[name=new_password]').val(password);
    },

  },

  initialize: function () {

    var self = this;

    this.model = new Backbone.Model();

    this.model.on('change:username', function (model, value) {
      self.$el.find('[name=username]').val(value);
    });
  },

  refresh: function () {
    this.model.clear();
  },

  save: function () {
    var self = this;
    var vals = this.$el.find('form').serializeArray();
    var data = {};

    for (var i in vals) {
      data[vals[i].name] = vals[i].value;
    }

    // validations
    var errors = false;

    // username from form should match id
    if (!data.username || data.username === "") {
      errors = true;
      mediator.trigger('toast:warning', "Missing username argument");
    }

    if (!data.new_password || data.new_password === "") {
      errors = true;
      mediator.trigger('toast:warning', "Password is required");
    }

    if (!data.requirePasswordChange || data.requirePasswordChange === "") {
      errors = true;
      mediator.trigger('toast:warning', "Missing password change option");
    }


    if (errors === false) {

      // Continue with account creation/update
      mediator.api("account/update", data, function (err, account) {
        if (err) {
          mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
          return;
        }

        mediator.trigger("modal:view", {
          title: "Password updated successfully.",
          view: new ConfirmationView({
            new_password: data.new_password,
            username: data.username,
            requirePasswordChange: String(data.requirePasswordChange) === "true"
          })
        });

        window.history.back();

      });
    }
  },

  template: 'dashboardAccountReset.mustache'
});