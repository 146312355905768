var BaseView = require('./base');
var mediator = require('../mediator');
var jQuery = require('jquery');

module.exports = BaseView.extend({

  close: function () {
    this.isOpen = false;
    jQuery('body').attr('data-menu', 'closed');
  },

  events: {
    "click": function() {
    },

    "click .toggle": function () {
      this.toggle();
    },

    "click [data-account]": function () {
      mediator.trigger("page:show", "account");
      this.close();
    },

    "click [data-dashboard]": function () {
      mediator.trigger("page:show", "dashboard");
      this.close();
    },

    "click [data-home]": function () {
      mediator.trigger("page:home");
      this.close();
    },

    "click [data-signout]": function () {
      window.location.reload();
    }
  },

  initialize: function () {

    var self = this;
    this.isOpen = false;

    jQuery('body').attr('data-menu', 'closed');

    this.listenTo(mediator, "menu:close", function () {
      self.close();
    });

    this.listenTo(mediator, "menu:open", function () {
      self.open();
    });

    this.listenTo(mediator, "menu:toggle", function () {
      self.toggle();
    });
  },

  open: function () {
    this.isOpen = true;
    jQuery('body').attr('data-menu', 'open');
  },

  template: 'mainMenu.mustache',

  toggle: function () {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

});