var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  events: {
    "click .edit": function () {
      window.location.hash = "#dashboard/accountEdit/" + this.options.username;
    },

    "click .reset": function () {
        window.location.hash = "#dashboard/accountReset/" + this.options.username;
    },
  },

  template: 'dashboardAccountItem.mustache'
});