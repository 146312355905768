var BaseView = require('./base');
var jQuery = require('jquery');
var HeaderView = require('./header');
var QuestionView = require('./question');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {
  },

  beforeRender: function () {

    var self = this;

    self.removeView('.questions');

    if (self.options.config.questions) {
      for (var i in self.options.config.questions) {

        if (self.options.config.questions[i].header) {
          self.insertView('.questions', new HeaderView(self.options.config.questions[i]));
        } else if (self.options.config.questions[i].question) {
          self.insertView('.questions', new QuestionView(self.options.config.questions[i]));
        }
      }
    }

    // Grab text of disclaimer text we are signing
    var disclaimer = self.options.markup;

    self.removeView('.accept');
    this.setView('.accept', new QuestionView({
      question: this.options.config.question,
      disclaimer: disclaimer
    }));
  },

  events: {},

  initialize: function () {

    var self = this;

    jQuery.ajax('templates/' + this.options.config.templateFile, {
      error: function () {
        alert('Error loading disclaimer.');
      },

      success: function (data) {
        self.options.markup = mediator.template.text(data, {
          participant: mediator.alter('participant'),
          screening: mediator.alter('screening')
        });
        self.render();
      }
    });
  },

  template: 'registrationDisclaimerPage.mustache',

  validate: function () {
    var valid = this.views['.accept'].validate();

    for (var i in this.views['.questions']) {

      if (this.views['.questions'][i].validate) {

        if (this.views['.questions'][i].checkCondition() === true) {
          if (this.views['.questions'][i].validate() === false) {
            valid = false;
          }
        }
      }
    }

    if (valid) {
      var participant = mediator.alter('participant');
      mediator.api('participant/status', {
        participantID: participant.participantID,
        status: 'hotlist'
      }, function (err, data) {
        mediator.trigger('participant:status', data);
      });
    }

    return valid;
  }
});
