var BaseView = require('./base');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function() {

    var self = this;
    if (!this.accounts) {
      //Get list of accounts
      mediator.api('account/list', {}, function(err, returnData) {
        self.accounts = returnData;
        var o = '<option value="">-- General Pool -- </option>';
        for (var i in returnData) {
          o += '<option value="' + returnData[i].username + '">' + returnData[i].username + '</option>';
        }
        self.$el.find('[name=assignedTo]')
            .html(o);
        self.$el.find('[name=assignedTo]')
            .val(self.options.assignedTo);
      });
    }
  },

  beforeRender: function() {
    if (this.options.status === 'offline') {
      this.options.offline = true;
      this.options.online = false;
    } else {
      this.options.offline = false;
      this.options.online = true;
    }
  },

  events: {
    "click [data-offline]": function() {
      var self = this;
      mediator.api("device/status", {
        deviceID: this.options.deviceID,
        status: "offline"
      }, function(err, returnData) {
        self.options = returnData;
      });
    },
    "click [data-online]": function() {
      var self = this;
      mediator.api("device/status", {
        deviceID: this.options.deviceID,
        status: "idle"
      }, function(err, returnData) {
        self.options = returnData;
      });
    },
    "change [name=assignedTo]": function() {
      var self = this;
      mediator.api("device/assign", {
        deviceID: this.options.deviceID,
        assignedTo: self.$el.find('[name=assignedTo]')
            .val()
      }, function(err, returnData) {
      });
    },
    "change [name=pool]": function() {
      var self = this;
      mediator.api("device/pool", {
        deviceID: this.options.deviceID,
        pool: self.$el.find('[name=pool]')
            .val()
      }, function(err, returnData) {
      });
    },
    "click [data-close]": function() {
      mediator.trigger("modal:close");
    }
  },

  initialize: function() {
    var self = this;
    this.update();
  },

  suspend: function() {
    clearTimeout(this.timeout);
  },

  template: 'dashboardDeviceDetail.mustache',

  update: function() {

    var self = this;
    mediator.api("device/get", {
      deviceID: this.options.deviceID
    }, function(err, returnData) {
      self.options = returnData;

      self.$el.find('.type')
          .html(self.options.type);
      self.$el.find('.serialNumber')
          .html(self.options.serialNumber);
      self.$el.find('.status')
          .html(self.options.status);
      self.$el.find('.assignedTo')
          .val(self.options.assignedTo);
      self.$el.find('.pool')
          .val(self.options.pool);

      self.$el.find('[data-offline]')
          .hide();
      self.$el.find('[data-online]')
          .hide();

      switch (self.options.status) {
        case 'offline':
          self.$el.find('[data-online]')
              .show();
          break;

        default:
          self.$el.find('[data-offline]')
              .show();
          break;
      }

      self.timeout = setTimeout(function() {
        self.update();
      }, 2000);
    });
  }
});