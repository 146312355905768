var BaseView = require('./base');
var Backbone = require('backbone');
var mediator = require('../mediator');
var ConfirmationView = require('../views/dashboardAccountConfirmation');

module.exports = BaseView.extend({

  afterRender: function () {

    var self = this;

    if (this.options.args &&
        this.options.args[0] &&
        this.model.has('username') === false) {
      mediator.api("account/get", {
        username: this.options.args[0]
      }, function (err, data) {
        self.model.set(data);
      });
    }

    // Grab set of all available roles
    if (self.model.has('roleList') === false) {
      mediator.api("account/roles", {}, function (err, roles) {
        self.model.set('roleList', roles);
      });
    }

  },


  beforeRender: function () {
    // Determine if we are adding a new account based on lack of ID passed in
    // This will enable additional account edit fields (such as username, password, etc)
    this.options.newAccount = !(this.options.args && this.options.args[0]);
    if (!this.options.newAccount) {
      this.options.username = this.options.args[0];
    }
    else {
      this.options.username = "";
    }

  },

  events: {
    'click [data-cancel]': function () {
      window.history.back();
    },

    'click [data-save]': function () {
      this.save();
    },

    'click [data-delete]': function () {
      this.delete();
    },

    'click [data-pwgen]': function () {
      // Generate a temporary password
      var password = mediator.alter('pwgen').password;
      this.$el.find('[name=new_password]').val(password);
    },

    'click [data-pwreset]': function () {
      // Show password reset form
      this.$el.find('[name=reset-fields]').show('slow');
    },


    'change [name=roleList]': function (e) {

    },

    'click [name=active]': function (e) {
      this.model.set('active', e.target.checked);
    }

  },

  initialize: function () {

    var self = this;

    this.model = new Backbone.Model();

    this.model.on('change:username', function (model, value) {
      self.$el.find('[name=username]').val(value);
    });

    this.model.on('change:active', function (model, value) {
      self.$el.find('[name=active]').prop('checked', String(value) == "true");
    });

    this.model.on('change:roleList', function (model, value) {
      var o = '<option value="">-- Select One -- </option>';
      for (var i in value) {
        o += '<option value="' + value[i].name + '">' + value[i].display + '</option>';
      }
      self.$el.find('[name=accountRole]').html(o);

      if (self.model.has('roles') && self.model.get('roles').length > 0) {
        var role = self.model.get('roles')[0];
        self.$el.find('[name=accountRole]').val(role.name);
      }
    });


  },

  refresh: function () {
    this.model.clear();
  },

  save: function () {
    var self = this;
    var vals = this.$el.find('form').serializeArray();
    var data = {};
    var updateRole = false;

    for (var i in vals) {
      data[vals[i].name] = vals[i].value;
    }

    // validations
    var errors = false;

    // Validate differently if adding a new account
    // Validate username is unique and contains valid characters
    if (this.options.newAccount) {
      if (!data.username || data.username === "") {
        errors = true;
        mediator.trigger('toast:warning', "Username is required");
      }
      else if (!data.username.match(/^[\w]+$/)) {
        errors = true;
        mediator.trigger('toast:warning', "Username can only contain letters, numbers and underscore");
      }

      if (!data.new_password || data.new_password === "") {
        errors = true;
        mediator.trigger('toast:warning', "Password is required");
      }

      if (!data.requirePasswordChange || data.requirePasswordChange === "") {
        errors = true;
        mediator.trigger('toast:warning', "Missing password change option");
      }
    }
    else {
      // username from form should match id
      if (!data.username || data.username !== this.options.username) {
        errors = true;
        mediator.trigger('toast:warning', "Mismatch Username, cannot be changed");
      }
    }

    if (!data.active || data.active === "") {
      errors = true;
      mediator.trigger('toast:warning', "Active flag is required");
    }

    // Validate accountRole is set
    if (!data.accountRole || data.accountRole === "") {
      errors = true;
      mediator.trigger('toast:warning', "Account Role is required");
    }
    else {
      // Check if we need to update account role
      if (self.model.has('roles') && self.model.get('roles').length > 0) {
        var role = self.model.get('roles')[0];
        // Only update role if selected role has changed
        updateRole = (role.name !== data.accountRole);
      }
      else {
        // This is a new user account being added
        updateRole = true;
      }
    }

    if (errors === false) {

      // Look up username for existing account
      mediator.api("account/get", {
        username: data.username
      }, function (err, user) {

        // if this is a new account, expect lookup failure
        if (self.options.newAccount && !err) {
          mediator.trigger("toast:warning", "Username exists, must be unique");
          return;
        }

        // Continue with account creation/update
        mediator.api("account/update", data, function (err) {
          if (err) {
            mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
            return;
          }

          if (updateRole === true) {
            mediator.api("account/changeRole", {
              username: data.username,
              role: data.accountRole,
            }, function (err) {
              if (err) {
                mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
                return;
              }

              self.displaySuccess(data);
            });

          }
          else {
            self.displaySuccess(data);
          }
        });
      });
    }
  },

  displaySuccess: function(data) {

    // If we changed the password, display modal with new credentials
    if (data.new_password && data.new_password !== "") {
      mediator.trigger("modal:view", {
        title: "Account saved successfully.",
        view: new ConfirmationView({
          new_password: data.new_password,
          username: data.username,
          requirePasswordChange: String(data.requirePasswordChange) == "true"
        })
      });
    }
    else {
      // Else just display success toast
      mediator.trigger("toast:success", "Account saved successfully.");
    }

    window.history.back();
  },

  delete: function () {

    var self = this;
    var vals = this.$el.find('form').serializeArray();
    var data = {};

    for (var i in vals) {
      data[vals[i].name] = vals[i].value;
    }

    if (!data.username || data.username === "") {
      errors = true;
      mediator.trigger('toast:warning', "No username specified");
      return;
    }
    var currentUser = mediator.alter('account');

    if (currentUser.username === data.username) {

      mediator.trigger("toast:warning", '{{ t "account.cannotDeleteActive" }}');

      return;
    }

    mediator.trigger("modal:confirm", {
      text: 'Are you sure you want to delete this account?',
      title: 'Confirm account deletion',
      buttons: [
        'Delete',
        'Cancel'
      ]
    }, function (err, buttonText) {

      if (buttonText === "Delete") {
        mediator.api("account/delete", {
          username: data.username
        }, function (err) {

          if (err) {
            mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

            return;
          }

          mediator.trigger("toast:success", "Account deleted successfully.");
          window.history.back();
        });
      }
    });
  },

  template: 'dashboardAccountEdit.mustache'
});