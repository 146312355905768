var BaseView = require('./base');
var mediator = require('../mediator');

var CalculatedView = require('./questionCalculated');
var CheckboxView = require('./questionCheckbox');
var NumericView = require('./questionNumeric');
var MultipleView = require('./questionMultiple');
var ScriptedView = require('./questionScripted');
var SignatureView = require('./questionSignature');
var SelectView = require('./questionSelect');
var TextAreaView = require('./questionTextArea');
var TextView = require('./questionText');
var HeightView = require('./questionHeight');

module.exports = BaseView.extend({

  afterRender: function () {
    this.checkCondition();
  },

  checkCondition: function () {

    if (this.options.question.condition) {
      var result = mediator.alter('condition', {
        conditions: this.options.question.condition
      });

      if (result.success === true) {
        this.$el.show('slow');
      } else {
        this.$el.hide('slow');
      }

      return result.success;
    }

    return true;
  },

  events: {},

  initialize: function () {
    var self = this;
    this.options.name = this.options.question;

    // Handle checking question conditions
    /*
     self.on('afterRender', function () {
     self.checkCondition();
     });
     self.listenTo(Backbone, 'response:update', function () {
     self.checkCondition('slow');
     });*/

    this.loadQuestion(function () {
      self.loadResponse(function () {
        self.loadView();
      });
    });
  },

  loadQuestion: function (callback) {
    var self = this;
    /*
     self.stopListening(Backbone, 'response:' + self.options.questionName +
     ':update');
     self.listenTo(Backbone, 'response:' + self.options.questionName +
     ':update', function (data) {
     self.options.response = data;
     self.render();
     });*/

    // Load question data
    this.options.question = {
      name: this.options.name
    };
    mediator.alter('question', this.options.question);

    if (callback) {
      callback();
    }
  },

  loadResponse: function (callback) {
    var response = mediator.alter('response', {
      question: this.options.name
    });
    this.options.response = response;

    if (callback) {
      callback();
    }
  },

  loadView: function () {
    var self = this;

    // TODO: Look at moving this to modules to define question views
    switch (self.options.question.questionType) {

      case 'calculated':
        self.setView('', new CalculatedView({
          options: self.options,
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'checkbox':
        self.setView('', new CheckboxView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'height':
        self.setView('', new HeightView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'multiple':
        self.setView('', new MultipleView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'numeric':
        self.setView('', new NumericView({
          options: self.options,
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'scripted':
        self.setView('', new ScriptedView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'select':
        self.setView('', new SelectView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'signature':
        self.setView('', new SignatureView({
          options: self.options,
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'textarea':
        self.setView('', new TextAreaView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      case 'text':
        self.setView('', new TextView({
          question: self.options.question,
          name: self.options.name,
          response: self.options.response
        }));
        break;

      default:
        console.error('Unsupported question type', self.options.question.questionType, self.options.question);
    }
  },

  template: 'question.mustache',

  validate: function () {

    if (this.views[''] && this.views[''].validate) {
      return this.views[''].validate();
    }

    console.log('missing validate on question', this);

    return true;
  }
});